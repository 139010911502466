@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap);
body{margin:0;font-family:"Manrope", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}html,body,#root{min-height:100%;height:100%;box-sizing:border-box}#root{background-color:black}

#aum-performance-chart{height:100%}.aum-performance-chart-card{background:#141414;box-sizing:border-box;color:#fff;height:100%;padding:16px;-webkit-user-select:none;user-select:none}

#aum-reward-estimated-chart{height:calc(100% - 24px)}.aum-reward-estimated-card{background:#141414;box-sizing:border-box;color:#fff;height:100%;padding:16px;-webkit-user-select:none;user-select:none}.dot{border-radius:4px;height:8px;margin-right:4px;width:8px}.legend-wrapper{align-items:center;display:flex;font-size:12px;margin-top:8px}

.g2-tooltip{background-color:#2b2a2a !important;color:#fff !important;box-shadow:none !important;opacity:1}

.tooltipDialog{display:inline-flex;justify-content:center;align-items:center;position:relative;margin-left:4px}.tooltipDialog__dialog{position:absolute;width:328px;background-color:#2a2e34;border-radius:16px;padding:16px;top:28px;right:-186px;z-index:100}.tooltipDialog__dialog.dialog--left{top:unset;left:28px;right:0}.tooltipDialog__dialog__title{font-weight:700;font-size:12px;line-height:16px;text-align:left;color:#ffffff;margin:0;margin-bottom:8px}.tooltipDialog__dialog__content{font-weight:400;font-size:12px;line-height:16px;text-align:left;color:#999999;white-space:pre-line}

.card{background:#141414;padding:24px 24px 24px 24px;color:#fff;-webkit-user-select:none;user-select:none}.card__title{font-weight:bold;font-size:14px;line-height:19px}.card__title__icon{margin-right:8px}.card__content__row{display:flex}.card__content__item{flex:1 1}.card__content__item p{margin:0 0 4px 0}.text{font-weight:bold;font-size:14px;line-height:19px}.text.text--white--sm{font-weight:bold;font-size:12px;line-height:16px}.text.text--gray{color:#808080}.text.text--green{color:#01d183}.tooltip__wrapper{display:flex;align-items:center}

.g2-tooltip{background-color:#2b2a2a !important;color:#fff !important;box-shadow:none !important;opacity:1}.chartWrapper{width:100%;height:100%;position:relative;background-color:#141414}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.xrexForm{width:100%;height:100%}

