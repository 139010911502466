.tooltipDialog {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left:4px;

  &__dialog {
    position: absolute;
    width: 328px;
    background-color: #2a2e34;
    border-radius: 16px;
    padding: 16px;
    top: 28px;
    right: -186px;
    z-index: 100;

    &.dialog--left {
      top: unset;
      left: 28px;
      right: 0;
    }

    &__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #ffffff;
      margin: 0;
      margin-bottom: 8px;
    }

    &__content {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #999999;
      white-space: pre-line;
    }
  }
}
