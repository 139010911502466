#aum-performance-chart {
  height: 100%;
}
.aum-performance-chart-card {
  background: #141414;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
  padding: 16px;
  user-select: none;
}