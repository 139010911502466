#aum-reward-estimated-chart {
  height: calc(100% - 24px);
}
.aum-reward-estimated-card {
  background: #141414;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
  padding: 16px;
  user-select: none;
}
.dot {
  border-radius: 4px;
  height: 8px;
  margin-right: 4px;
  width: 8px;
}
.legend-wrapper {
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-top: 8px;
}
