.card {
  background: #141414;
  padding: 24px 24px 24px 24px;
  color: #fff;
  user-select: none;
  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    &__icon {
      margin-right: 8px;
    }
  }

  &__content {
    &__row {
      display: flex;
    }

    &__item {
      flex: 1;
      p {
        margin: 0 0 4px 0;
      }
    }
  }
}

.text {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  &.text {
    &--white {
      &--sm {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &--gray {
      color: #808080;
    }

    &--green {
      color: #01d183;
    }
  }
}

.tooltip__wrapper {
  display: flex;
  align-items: center;
}
