.g2-tooltip {
  background-color: #2b2a2a !important;
  color: #fff !important;
  box-shadow: none !important;
  opacity: 1;
}

.chartWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #141414;
}
